import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_video_box()
})

function dhsv_vc_video_box() {
  $('.dhsv_video_box__container img').on('click', function () {
    const embed = $(this).parent().find('embed')[0]
    if (embed) {
      const src = embed.src
      embed.src = src + (src.includes('?') ? '&' : '?') + 'autoplay=1'
    }
    $(this).hide()
  })
}
