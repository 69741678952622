import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_hubspot_select()
})

function dhsv_vc_hubspot_select() {
  console.log('%c 21313421:', 'color: #7dd3fc', 21313421) //eslint-disable-line
  $('.dhsv_hubspot_forms').each(function () {
    const $this = $(this)
    const select = $this.find('select')
    select.on('change', function () {
      const val = $(this).val()
      console.log('%c val:', 'color: #7dd3fc', val) //eslint-disable-line
      $this.find('.dhsv_hubspot_forms__item').hide()
      $this.find('.dhsv_hubspot_forms__item[data-form-id="' + val + '"]').show()
      console.log('%c need:', 'color: #7dd3fc', need) //eslint-disable-line
    })
  })
}
